import React, { useContext, useEffect, useState } from "react";
import { Link, navigate } from "gatsby";
import { FormattedMessage, useIntl } from "react-intl";

import { FirebaseContext } from "../components/Firebase";
import {
  ContentBg,
  ContentBox,
  Button,
  ErrorMessage,
  Form,
  Input,
  LoginWithGoogle,
} from "../components/ui";

const Login = () => {
  const [formValues, setFormValues] = useState({ email: "", password: "" });
  const { firebase, user } = useContext(FirebaseContext);
  const [errorMessage, setErrorMessage] = useState("");
  const intl = useIntl();

  let isMounted = true;

  if (user) {
    navigate("/profile");
  }

  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  function handleInputChange(e) {
    e.persist();
    setErrorMessage("");
    setFormValues(currentValues => ({
      ...currentValues,
      [e.target.name]: e.target.value,
    }));
  }

  function handleSubmit(e) {
    e.preventDefault();

    firebase
      .login({ email: formValues.email, password: formValues.password })
      .then(() => navigate("/profile"))
      .catch(error => {
        if (isMounted) {
          if (error.message === "Firebase: Error (auth/user-not-found).") {
            setErrorMessage(
              "Doesn't look like you have an account yet - click below to create one."
            );
          } else {
            setErrorMessage(error.message);
          }
        }
      });
  }

  const loginWithGoogle = e => {
    e.preventDefault();
    firebase
      .signInWithGoogle()
      .then(() => navigate(-1))
      .catch(error => {
        setErrorMessage(error.message);
      });
  };

  return (
    <ContentBg>
      <ContentBox>
        <h2>
          {" "}
          <FormattedMessage id="login" />
        </h2>
        <Form onSubmit={handleSubmit}>
          <p>
            <strong>
              <FormattedMessage id="quick_easy" />
            </strong>
          </p>
          <LoginWithGoogle onClick={loginWithGoogle} />

          <p>
            <strong>
              <FormattedMessage id="login_with_password" />:
            </strong>
          </p>
          {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <Input
            required
            value={formValues.email}
            name="email"
            onChange={handleInputChange}
            placeholder={intl.formatMessage({ id: "email" })}
            type="email"
          />
          <Input
            required
            value={formValues.password}
            name="password"
            onChange={handleInputChange}
            placeholder={intl.formatMessage({ id: "password" })}
            type="password"
          />
          <Button type="submit" block>
            <FormattedMessage id="login_with_password" />
          </Button>
        </Form>
        <p>
          <FormattedMessage id="no_account_yet" />{" "}
          <Link to="/register">
            {" "}
            <FormattedMessage id="click_here_to_create_one" />
          </Link>
          . <FormattedMessage id="takes_no_more_than_30_seconds" />
        </p>
      </ContentBox>
    </ContentBg>
  );
};

export default Login;
